import { LoginPageTranslations } from './types';

export const deTranslations: LoginPageTranslations = {
    title: "Nur Admin-Login",
    labels: {
        email: "E-Mail",
        password: "Passwort",
        loginButton: "Anmelden"
    },
    heading: "Anmelden",
};
