import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MainPageLayout from "../../../components/header/MainPageLayout";
import Table from "../../../components/table/Table";
import Pill from "../components/Pill";
import {
  TableCard,
  PageTitleSection,
  PageTitle,
  ActionsCard,
  ActionButtons,
} from "./InstitutionsTable.styles";
import { GetAllInstitutionsType, Institution } from "../../../core/ServerApiLayer/VOne/institutions/get_all_instititions";
import {getJwt} from "../../../core/LocalStorage/AuthSession/core";


interface InstitutionTableProps {
  getAllInstitutions: GetAllInstitutionsType;
}


export const InstitutionTable: React.FC<InstitutionTableProps> = ({ getAllInstitutions }) => {
  const navigate = useNavigate(); // Move useNavigate inside the component

  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInstitutions() {
      const jwt = getJwt();
      //@ts-ignore
      const response = await getAllInstitutions(jwt);
      if (response.status === 200 && response.data) {
        setInstitutions(response.data);
      }
      setLoading(false);
    }

    fetchInstitutions();
  }, [getAllInstitutions]);

  const columns = [
    {
      key: "name",
      label: "Institution Name",
    },
    {
      key: "status",
      label: "Status",
      render: (row: Institution) => <Pill>{row.status}</Pill>,
    },
    {
      key: "no_of_licenses",
      label: "No. of Licenses",
    },
    {
      key: "no_of_users",
      label: "No. of Active Users",
    },
    {
      key: "actions",
      label: "Actions",
      render: (row: Institution) => (
          <ActionsCard>
            <ActionButtons backgroundColorHex="#278230" onClick={() => navigate(`/institutions/${row.entity_id}`)}>
              View Departments
            </ActionButtons>
            <ActionButtons backgroundColorHex="#cc0000" onClick={() => navigate(`/users/${row.entity_id}`)}>
                View Users
            </ActionButtons>
          </ActionsCard>
      ),
    },
  ];

  return (
      <MainPageLayout>
        <TableCard>
          <PageTitleSection>
            <PageTitle>Institutions</PageTitle>
          </PageTitleSection>
          <Table
              data={institutions}
              //@ts-ignore
              columns={columns}
              noRowsText={loading ? "Loading..." : "No institutions found"}
          />
        </TableCard>
      </MainPageLayout>
  );
};

export default InstitutionTable;
