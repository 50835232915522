import axios, { AxiosResponse } from "axios";
import { InstitutionUrl } from "./AUrl";

/*
The interface for an institution entity returned by the API.

@entity_id: The unique identifier for the institution.
@status: The status of the institution (e.g., active, inactive).
@no_of_licenses: The number of licenses associated with the institution.
@no_of_users: The number of users within the institution.
*/
export interface Institution {
    entity_id: number;
    name: string;
    status: string;
    no_of_licenses: number;
    no_of_users: number;
}

/*
The interface for the response from the get all institutions endpoint.

@status: The HTTP status code.
@error: The error message if any.
@data: The list of institutions returned from the endpoint.
*/
export interface GetAllInstitutionsResponse {
    status: number;
    error?: string;
    data?: Institution[];
}

// Function Signature
export type GetAllInstitutionsType = (jwt: string) => Promise<GetAllInstitutionsResponse>;

/*
The function to get all institutions.

@jwt: The JWT of the user requesting the institutions.

@returns: The ApiResponse from the get all institutions endpoint.
*/
export async function getAllInstitutions(jwt: string): Promise<GetAllInstitutionsResponse> {
    const url = new InstitutionUrl().get_all;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.get<GetAllInstitutionsResponse | string>(url, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true // Resolve promise for all HTTP status codes
        });
    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as Institution[]
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
