// External imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal imports
import {
  FormContainer,
  LeftPanel,
  LeftPanelInsideContainer,
  Title,
  LogoImage,
  FormSection,
  RightPanel,
  LanguageSectionWrapper,
  LanguageHeaderEmptyArea,
    Heading,
} from "./LoginPage.styles";
import { LoginForm, FormData } from "./FormComponent/FormComponent";
import { ModalRenderer } from "../../components/header/ModalRenderer";
// @ts-ignore
import myImage from "../../../assets/outside.jpg";
// @ts-ignore
import logoImage from "../../assets/clinical_metrics.png";

// Dependencies injections for the login page
import {LoginUserType, LoginResponse} from "../../core/ServerApiLayer/VOne/auth/login";
import {SetFromApiDataType} from "../../core/LocalStorage/AuthSession/core";

// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";

// Translations
import { LoginPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, LoginPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * Props for the LoginPage component
 */
export interface LoginPageProps {
  setSessionData: SetFromApiDataType;
  loginApiCall: LoginUserType;
}



/**
 * LoginPage component
 * Displays a 404 error page when a route doesn't exist
 * Provides a way to navigate back to the home page
 */
const LoginPage: React.FC<LoginPageProps> = ({ setSessionData, loginApiCall }) => {
  const { language, setLanguage } = useLanguage();
  //@ts-ignore
  const translations = TRANSLATIONS[language];

  // useNavigate is a hook from react-router-dom that allows us to navigate to different pages
  const navigate = useNavigate();

    // define the state for the login form
  const [login, setLogin] = useState<boolean>(true);

  // define the state for the form data (will be passed into the login API call)
  const [formStatus, setFormStatus] = useState<FormData>({
    email: "",
    password: "",
    success: false,
    failure: false,
    error: "",
    loading: false,
  });


  // handle the form submit event by calling the backend to login
  const handleFormSubmit = async (userData: FormData) => {
    // set the form to loading while the request is being made as hashing the password can take a while
    setFormStatus({ ...userData, loading: true });

    // login the user
    const response = await loginApiCall(userData.email, userData.password);
    if (response.status === 200 && response.data) {
      console.log(response.data);
      const loginResponse: LoginResponse = response.data;
      // store the token in the local storage
      setSessionData(
          loginResponse.user_session,
          loginResponse.jwt,
          loginResponse.refresh_token
      );

      navigate("/home");

      // check to see if the user has more roles prompting role choice if multiple roles
      if (!loginResponse.roles) {
        setFormStatus({
          ...userData,
          success: true,
          failure: false,
          error: "",
        });
        return null;
      }
    }
    // the initial login request failed
    else {
      // @ts-ignore
      setFormStatus({
        ...userData,
        success: false,
        failure: true,
        error: response.error ?? "",
        loading: false,
      });
      return null;
    }
  };



  return (
    <FormContainer style={{ backgroundImage: `url(outside.jpg)` }}>
      <LanguageSectionWrapper>
        <LanguageHeaderEmptyArea></LanguageHeaderEmptyArea>
      </LanguageSectionWrapper>

      <LeftPanel>
        <LeftPanelInsideContainer>
          <LogoImage
              src={logoImage}
              alt="description"
              onClick={() => navigate("/")}
          />
          {!formStatus.success && !formStatus.loading && (
              <Title>{translations.title}</Title>
          )}
          <FormSection>
            <LoginForm
              onSubmit={handleFormSubmit}
              initialFormData={formStatus}
            />
          </FormSection>
        </LeftPanelInsideContainer>
      </LeftPanel>
      <RightPanel>{/*    */}</RightPanel>
      <ModalRenderer />
    </FormContainer>
  );
};

export default LoginPage;
