import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewUsersTable from "./components/ViewUsersTable/ViewUsersTable";
import MainPageLayout from "../../components/header/MainPageLayout";
import { PageTitle, PageTitleSection, TableCard } from "./ViewUsersPage.styles";
// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";
import { getJwt } from "../../core/LocalStorage/AuthSession/core";
import { GetAllUsersType, User } from "../../core/ServerApiLayer/VOne/institutions/get_users";

// Translations
import { UsersPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, UsersPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

export interface ViewUsersPageProps {
  getAllUsers: GetAllUsersType;
}

/**
 * ViewUsersPage Component
 *
 * This component fetches and displays users for either an institution or a specific department.
 * Admins can manage roles and licenses.
 *
 * @returns A ViewUsersPage component
 */
function ViewUsersPage({ getAllUsers }: ViewUsersPageProps) {
  const { language } = useLanguage();
  const { institutionId, departmentId } = useParams<{ institutionId: string; departmentId?: string }>();

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUsers() {
      const jwt = getJwt();

      if (!institutionId) {
        console.error("Institution ID is required.");
        setLoading(false);
        return;
      }

      // Call API with institutionId and optional departmentId
      //@ts-ignore
      const response = await getAllUsers(jwt, Number(institutionId), departmentId ? Number(departmentId) : undefined);

      if (response.status === 200 && response.data) {
        console.log(response.data);
        // Filter roles for the given institution and department (if department exists)
        const filteredUsers = response.data.map(user => ({
          ...user,
          role: user.role[0].role_name,
        }));
        //@ts-ignore
        setUsers(filteredUsers);
      }
      setLoading(false);
    }

    fetchUsers();
  }, [getAllUsers, institutionId, departmentId]);

  //@ts-ignore
  const translations = TRANSLATIONS[language];

  return (
      <MainPageLayout>
        <TableCard>
          <PageTitleSection>
            <PageTitle>{translations.title}</PageTitle>
          </PageTitleSection>
          <ViewUsersTable data={loading ? [] : users} />
        </TableCard>
      </MainPageLayout>
  );
}

export default ViewUsersPage;
