import { VOneUrl } from "../url";


/*
The Url endpoints for the User API.

Attributes:
    base: The base url.
    create: The url for creating a user.
    login: The url for logging in a user.
 */
export class UserUrl extends VOneUrl {

    public base: string;
    public create: string;
    public login: string;


    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/user/";
        this.create = this.base + "create";
        this.login = this.base + "login";
    }

}
