import styled from "@emotion/styled";

// Main container for the form
export const FormContainer = styled.div`
  flex-grow: 1; /* Takes the remaining space */
  background-color: #e0e0e0; /* Just for visibility, change as needed */
  background-size: cover; /* Cover the entire area */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Optional: Fix the background during scroll */
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  overflow: hidden; /* Prevent scrolling within the container */
`;

export const LeftPanel = styled.div`
  font-family: "Helvetica", "Arial", sans-serif;
  padding: 20px;
  width: 40%; /* 40% of the container's width */
  min-width: 300px;
  max-width: 600px;
  background-color: white;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically if needed */
  height: 100vh; /* Full height of the viewport */
  /*padding: 40px 0; !* 40px top and bottom padding *!*/
  overflow: hidden; /* Prevent scrolling within the container */
`;

export const LeftPanelInsideContainer = styled.div`
  min-width: 240px;
`;

export const RightPanel = styled.div`
  flex-grow: 1; /* Takes the remaining space */
  background-color: #e0e0e0; /* Just for visibility, change as needed */
  background-size: cover; /* Cover the entire area */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Optional: Fix the background during scroll */
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  overflow: hidden; /* Prevent scrolling within the container */
`;

export const Title = styled.h1`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
`;

export const Logo = styled.img`
  max-width: 200px;
  margin-bottom: 20px; /* Add space between the logo and the title */
  cursor: pointer;
`;

export const FormSection = styled.div`
  margin: "0px";
`;

export const LanguageSectionWrapper = styled.div`
  background: #e0f2fe;
  display: flex;
  width: 100%;
  padding: 10px 5px;
`;

export const LanguageHeaderEmptyArea = styled.div`
  width: 90%;
`;

// Logo image style in the left panel
export const LogoImage = styled.img`
  max-width: 200px;
  margin-bottom: 20px; /* Add space between the logo and the title */
  cursor: pointer;
`;


export const Heading = styled.h1`
padding-left:0px;
`;
