import axios, { AxiosResponse } from "axios";
import { InstitutionUrl } from "./AUrl";

/*
The interface for a department entity returned by the API.

@entity_id: The unique identifier for the department.
@status: The status of the department (e.g., active, inactive).
@no_of_licenses: The number of licenses associated with the department.
@no_of_users: The number of users within the department.
*/
export interface Department {
    entity_id: number;
    institution_id: number;
    name: string;
    status: string;
    no_of_licenses: number;
    no_of_users: number;
}

/*
The interface for the response from the get all departments endpoint.

@status: The HTTP status code.
@error: The error message if any.
@data: The list of departments returned from the endpoint.
*/
export interface GetAllDepartmentsResponse {
    status: number;
    error?: string;
    data?: Department[];
}

// Function Signature
export type GetAllDepartmentsType = (jwt: string, institutionId: number) => Promise<GetAllDepartmentsResponse>;

/*
The function to get all departments for a given institution.

@jwt: The JWT of the user requesting the departments.
@institutionId: The ID of the institution whose departments are being requested.

@returns: The ApiResponse from the get all departments endpoint.
*/
export async function getAllDepartments(jwt: string, institutionId: number): Promise<GetAllDepartmentsResponse> {
    const url = new InstitutionUrl().department_details;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<GetAllDepartmentsResponse | string>(url,
            { institution_id: institutionId },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': jwt
                },
                validateStatus: () => true // Resolve promise for all HTTP status codes
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as Department[]
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
