// External imports
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Internal imports
import "./styles/index.css";
import { MainPageRoutes } from "./pages/routes";
import NotFoundPage from "./pages/not-found-page/NotFoundPage";
import { FrontPage } from "./pages/front-page/FrontPage";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";

// dependencies for the login page
import { loginUser } from "../src/core/ServerApiLayer/VOne/auth/login";
import { setFromApiData } from "../src/core/LocalStorage/AuthSession/core";

// Language Context
import { LanguageProvider } from './core/translations/LanguageContext';
import LoginPage from "./pages/login-page/LoginPage";


/**
 * Compiles all routes for the application
 * @returns Array of route configurations
 */
function compileRoutes() {
    const routes = [
        {
            path: "/home",
            element: <FrontPage />,
        },
        {
            path: "/",
            element: <LoginPage
                setSessionData={setFromApiData}
                loginApiCall={loginUser}
            />,
        },
        {
            path: "/about",
            element: <AboutPage />,
        },
        {
            path: "/contact",
            element: <ContactPage />,
        },
        {
            path: "*",
            element: <NotFoundPage />,
        },
    ];

    return routes
        .concat(MainPageRoutes);
}

/**
 * Root component that provides language context to the entire app
 */
const Root: React.FC = () => {
    const router = createBrowserRouter(compileRoutes());

    return (
        <LanguageProvider>
            <RouterProvider router={router} />
        </LanguageProvider>
    );
};

// Initialize React root and render application
const rootElement = document.getElementById("root");
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<Root />);
} else {
    console.error("Failed to find root element");
}