import axios, { AxiosResponse } from "axios";
import { InstitutionUrl } from "./AUrl";
import { getJwt } from "../../../LocalStorage/AuthSession/core";

/*
The interface for a user entity returned by the API.

@id: The unique identifier for the user.
@user_name: The username or email of the user.
@license_key: The license key assigned to the user (nullable).
@expires: The expiration date of the license key (nullable).
@role: The role details assigned to the user.
*/
export interface User {
    id: number;
    user_name: string;
    license_key?: string | null;
    expires?: string | null;
    role: {
        id: number;
        role_id: number;
        institution_id: number;
        role_name: string;
        institution_name: string;
        date_assigned: string;
    }[];
}

/*
The interface for the response from the get all users endpoint.

@status: The HTTP status code.
@error: The error message if any.
@data: The list of users returned from the endpoint.
*/
export interface GetAllUsersResponse {
    status: number;
    error?: string;
    data?: User[];
}

// Function Signature
export type GetAllUsersType = (jwt: string, institutionId: number, departmentId?: number) => Promise<GetAllUsersResponse>;

/*
The function to get all users for a given institution (and optionally a department).

@jwt: The JWT of the user requesting the users.
@institutionId: The ID of the institution whose users are being requested.
@departmentId: (Optional) The ID of the department whose users are being requested.

@returns: The ApiResponse from the get all users endpoint.
*/
export async function getAllUsers(jwt: string, institutionId: number, departmentId?: number): Promise<GetAllUsersResponse> {
    const url = new InstitutionUrl().get_users;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<GetAllUsersResponse | string>(url,
            { institution_id: institutionId, department_id: departmentId },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': jwt
                },
                validateStatus: () => true // Resolve promise for all HTTP status codes
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as User[]
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
