import React, { useState } from "react";
import {
  ActionsCard,
  ActionButtons,
  ViewUsersTableContainer,
  ActionButtonsWrapper,
} from "./ViewUsersTable.styles";
import Table from "../../../../components/table/Table";
import ChangeRoleModal from "../../../../components/modal/change-role-modal/ChangeRoleModal";
import ResetPasswordModal from "../../../../components/modal/reset-password/ResetPassword";
import AddRemoveLicenseKeyModal from "../../../../components/modal/add-remove-license-key/AddRemoveLicenseKey";
import CopyLicenceKey from "../CopyLicenceKey/CopyLicenceKey";
import { User } from "../../../../core/ServerApiLayer/VOne/institutions/get_users";

interface ViewUsersTableProps {
  data: User[];
}

/**
 * ViewUsersTable Component
 *
 * Displays a table of users, including their email, role, and license key.
 * Allows role changes, password resets, and license key management.
 */
export const ViewUsersTable: React.FC<ViewUsersTableProps> = ({ data }) => {
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showAddRemoveLicenseModal, setShowAddRemoveLicenseModal] = useState(false);
  const [selectedLicenseKeyRow, setSelectedLicenseKeyRow] = useState<User | null>(null);

  const columns = [
    {
      key: "user_name",
      label: "Email",
    },
    {
      key: "role",
      label: "Role",
    },
    {
      key: "license_key",
      label: "License Key",
      sort: true,
      //@ts-ignore
      render: (row: User) => row.license_key ? <CopyLicenceKey row={row} /> : null,
    },
    {
      key: "actions",
      label: "Actions",
      render: (row: User) => (
          <ActionsCard>
            <ActionButtonsWrapper>
              <ActionButtons backgroundColorHex="#278230" onClick={() => setShowChangeRoleModal(true)}>
                Change Role
              </ActionButtons>
            </ActionButtonsWrapper>
            <ActionButtonsWrapper>
              {row.user_name && (
                  <ActionButtons onClick={() => setShowResetPasswordModal(true)}>
                    Reset Password
                  </ActionButtons>
              )}
            </ActionButtonsWrapper>
            <ActionButtonsWrapper>
              <ActionButtons
                  backgroundColorHex="#cc0000"
                  onClick={() => {
                    setSelectedLicenseKeyRow(row);
                    setShowAddRemoveLicenseModal(true);
                  }}
              >
                {row.license_key ? "Remove License" : "Add License"}
              </ActionButtons>
            </ActionButtonsWrapper>
          </ActionsCard>
      ),
    },
  ];

  return (
      <ViewUsersTableContainer>
        <Table
            data={data}
            //@ts-ignore
            columns={columns}
            noRowsText={data.length === 0 ? "No users found" : ""}
        />
        {showChangeRoleModal && (
            <ChangeRoleModal onRequestClose={() => setShowChangeRoleModal(false)} />
        )}
        {showResetPasswordModal && (
            <ResetPasswordModal onRequestClose={() => setShowResetPasswordModal(false)} />
        )}
        {showAddRemoveLicenseModal && selectedLicenseKeyRow && (
            <AddRemoveLicenseKeyModal
                onRequestClose={() => setShowAddRemoveLicenseModal(false)}
                selectedLicenseKeyRow={selectedLicenseKeyRow}
            />
        )}
      </ViewUsersTableContainer>
  );
};

export default ViewUsersTable;
