import { UserSession } from '../../ServerApiLayer/VOne/auth/login';


/*
The data to be stored in localStorage for the user session.

@jwt: The JWT token to be sent in the header of requests requiring authentication.
@refreshToken: To be sent to request a new JWT token when the JWT token expires.
@StoredUserSession: The information around the user session
                    (serialised from the UserSession from the login API call, these two are different).
 */
export interface UserData {
    jwt: string;
    refreshToken: string;
    userSession: StoredUserSession;
}


/*
Information around the user session to be referenced.

@id: The id of the user session.
@userId: The id of the user associated with the user session.
@tokenId: The unique id of the token associated with the user session.
@refreshTokenId: The unique id of the refresh token associated with the user session.
@dateCreated: The date that the user session was created.
@tokenExpires: The date that the JWT expires.
@refreshTokenExpires: The date that the refresh token expires.
@loggedOut: Whether the user is logged out of the session.
@role: The role associated with the auth session.
@entityType: The type of entity that the auth session is associated with.
@entityId: The id of the entity that the auth session is associated with.
 */
export interface StoredUserSession {
    id: number;
    userId: number;
    tokenId: string;
    refreshTokenId: string;
    dateCreated: Date; // Changed to Date
    tokenExpires: Date; // Changed to Date
    refreshTokenExpires: Date; // Changed to Date
    loggedOut: boolean;
    role?: string;
    entityType?: string;
    entityId?: number;
}


/*
Function to convert the UserSession from the login api call to StoredUserSession to be stored in localStorage.

@original: The UserSession from the login api call.

@returns: The StoredUserSession to be stored in localStorage.
 */
export function convertUserSession(original: UserSession): StoredUserSession {
    return {
        id: original.id,
        userId: original.user_id,
        tokenId: original.token_id,
        refreshTokenId: original.refresh_token_id,
        dateCreated: new Date(original.date_created),
        tokenExpires: new Date(original.token_expires),
        refreshTokenExpires: new Date(original.refresh_token_expires),
        loggedOut: original.logged_out,
        role: original.role,
        entityType: original.entity_type,
        entityId: original.entity_id
    };
}
