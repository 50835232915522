import { VOneUrl } from "../url";


/*
The Url endpoints for the Institution API.

Attributes:
    base: The base url.
    create: The url for creating a Institution.
    login: The url for logging in a Institution.
 */
export class InstitutionUrl extends VOneUrl {

    public base: string;
    public get_all: string;
    public department_details: string;
    public get_users: string;

    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/institutions/";
        this.get_all = this.base + "get/all";
        this.department_details = this.base + "departments/get";
        this.get_users = this.base + "users/get/all";
    }

}
