import { LoginPageTranslations } from './types';

export const enTranslations: LoginPageTranslations = {
    title: "Admin log in only",
    labels: {
        email: "Email",
        password: "Password",
        loginButton: "Login"
    },
    heading:"Login",
};