import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import MainPageLayout from "../../components/header/MainPageLayout";
import Table from "../../components/table/Table";
import Pill from "../viewInstitutionsPage/components/Pill";
import {
    TableCard,
    PageTitleSection,
    PageTitle,
    ActionsCard,
    ActionButtons,
} from "../viewInstitutionsPage/components/InstitutionsTable.styles";
import { GetAllDepartmentsType } from "../../core/ServerApiLayer/VOne/institutions/get_departments";
import { getJwt } from "../../core/LocalStorage/AuthSession/core";
import { Department } from "../../core/ServerApiLayer/VOne/institutions/get_departments";

interface DepartmentTableProps {
    getAllDepartments: GetAllDepartmentsType;
}

export const DepartmentTable: React.FC<DepartmentTableProps> = ({ getAllDepartments }) => {
    const [departments, setDepartments] = useState<Department[]>([]);
    const [loading, setLoading] = useState(true);
    const { id: institutionId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchDepartments() {
            const jwt = getJwt();
            //@ts-ignore
            const response = await getAllDepartments(jwt, Number(institutionId));
            if (response.status === 200 && response.data) {
                setDepartments(response.data.map(dept => ({
                    entity_id: dept.entity_id,
                    institution_id: dept.institution_id,
                    name: dept.name,
                    no_of_licenses: dept.no_of_licenses,
                    no_of_users: dept.no_of_users,
                    status: dept.status as "active" | "inactive" | "free trial"
                })));
            }
            setLoading(false);
        }

        fetchDepartments();
    }, [getAllDepartments, institutionId]);

    const columns = [
        {
            key: "institution_id",
            label: "Institution ID",
        },
        {
            key: "name",
            label: "Department Name",
        },
        {
            key: "status",
            label: "Status",
            render: (row: Department) => <Pill>{row.status}</Pill>,
        },
        {
            key: "no_of_licenses",
            label: "No. of Licenses",
        },
        {
            key: "no_of_users",
            label: "No. of Active Users",
        },
        {
            key: "actions",
            label: "Actions",
            render: (row: Department) => (
                <ActionsCard>
                    <ActionButtons backgroundColorHex="#278230" onClick={() => navigate(`/users/${institutionId}/${row.entity_id}`)}>
                        View Users
                    </ActionButtons>
                </ActionsCard>
            ),
        },
    ];

    return (
        <MainPageLayout>
            <TableCard>
                <PageTitleSection>
                    <PageTitle>Departments</PageTitle>
                </PageTitleSection>
                <Table
                    data={departments}
                    //@ts-ignore
                    columns={columns}
                    noRowsText={loading ? "Loading..." : "No departments found"}
                />
            </TableCard>
        </MainPageLayout>
    );
};

export default DepartmentTable;
