

/*
Configures the urls for the V0 API.
 */
export class VOneUrl {

    public base: string;

    /*
    The constructor sets the base url.
     */
    constructor() {
        this.base = this.defineRoot();
    }

    /*
    Defines the root url based on the hostname.

    Returns:
        The root url.
     */
    public defineRoot() {
        const hostname = window.location.hostname;

        if (hostname === "localhost" || hostname === "127.0.0.1") {
            return "http://0.0.0.0:8001/";
        } else if (hostname.includes("freshcutswags")) {
            return "https://freshcutswags.com/";
        } else if (hostname.includes("divinagame")) {
            return "https://divinagame.de/";
        } else if (hostname.includes("clinical-metrics-moqd2.ondigitalocean.app")) {
            // Base URL for testing environment on digital ocean
            return "https://clinical-metrics-moqd2.ondigitalocean.app/";
        } else if (hostname.includes("clinicalmetrics.co.uk")) {
            // Base URL for production environment on digital ocean
            return "https://clinicalmetrics.co.uk/";
        } else {
            throw new Error(`Hostname ${hostname} not recognized.`);
        }
    }
}
