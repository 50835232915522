import { AxiosResponse } from 'axios';
import axios from 'axios';
import { UserUrl } from './AUrl';


/*
The interface for the response from the login endpoint.
@jwt: The JWT token to be used for authentication.
@refresh_token: The refresh token to be used for refreshing the JWT token when the JWT token expires.
@roles: The roles of the user (will be empty if the user has only one role as they will automatically be logged in as
        that one role).
@user_session: The information around the user session.
 */
export interface LoginResponse {
    jwt: string;
    refresh_token: string;
    roles?: RolesResponse;
    user_session: UserSession;
}


/*
The interface showing the roles that are available to the user.

@department_roles: The roles that the user has in departments.
@institution_roles: The roles that the user has in institutions.
 */
export interface RolesResponse {
    department_roles: DepartmentRole[];
    institution_roles: InstitutionRole[];
}


/*
The interface showing the role that the user has in a department.

@id: The id of the role.
@role_id: The id of the role associated with the role allocation.
@department_id: The id of the department associated with the role allocation.
@role_name: The name of the role.
@department_name: The name of the department.
@date_assigned: The date that the role was assigned.
 */
export interface DepartmentRole {
    id: number;
    role_id: number;
    department_id: number;
    role_name: string;
    department_name: string;
    date_assigned: string; // NaiveDateTime in Rust, but string in TypeScript for simplicity
}


/*
The interface showing the role that the user has in an institution.

@id: The id of the role.
@role_id: The id of the role associated with the role allocation.
@institution_id: The id of the institution associated with the role allocation.
@role_name: The name of the role.
@institution_name: The name of the institution.
@date_assigned: The date that the role was assigned.
 */
export interface InstitutionRole {
    id: number;
    role_id: number;
    institution_id: number;
    role_name: string;
    institution_name: string;
    date_assigned: string;
}


/*
The interface showing the user session information.

@id: The id of the user session.
@user_id: The id of the user.
@token_id: The id of the token.
@refresh_token_id: The id of the refresh token.
@date_created: The date that the user session was created.
@token_expires: The date that the token expires.
@refresh_token_expires: The date that the refresh token expires.
@logged_out: Whether the user is logged out of the session.
@role: The role associated with the auth session.
@entity_type: The type of entity that the auth session is associated with.
@entity_id: The id of the entity that the auth session is associated with.
 */
export interface UserSession {
    id: number;
    user_id: number;
    token_id: string;
    refresh_token_id: string;
    date_created: string;
    token_expires: string;
    refresh_token_expires: string;
    logged_out: boolean;
    role?: string;
    entity_type?: string;
    entity_id?: number;
}


/*
The interface for the response from the login endpoint.

@status: The HTTP status code of the response.
@data: The data returned from the endpoint (empty if login failed).
@error: The error message returned from the endpoint (empty if login successful).
 */
export interface ApiResponse<T> {
    status: number;
    data?: T;
    error?: string;
}


export type LoginUserType = (username: string, password: string) => Promise<ApiResponse<LoginResponse>>;


/*
The function to login a user.

@username: The username of the user to login.
@password: The password of the user to login.

Returns the response from the login endpoint.
 */
export async function loginUser(username: string, password: string): Promise<ApiResponse<LoginResponse>> {
    const url = new UserUrl().login;
    let response: AxiosResponse<any>;
    try {
        response = await axios.get<LoginResponse | string>(url, {
            auth: {
                username,
                password
            },
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });
    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {

        // Successful response with LoginResponse
        return {
            status: response.status,
            data: response.data as LoginResponse
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
