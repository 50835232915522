// External Imports
import React from 'react';

// Defines the main pages routes
import NotFoundPage from "./not-found-page/NotFoundPage";
import ViewUsersPage from "./viewUsersPage/ViewUsersPage";
import InstitutionsTable from "./viewInstitutionsPage/components/InstitutionsTable";
import ViewDepartmentsTable from "./viewDepartmentsPage/ViewDepartmentsTable";
import {getAllInstitutions} from "../core/ServerApiLayer/VOne/institutions/get_all_instititions";
import {getAllDepartments} from "../core/ServerApiLayer/VOne/institutions/get_departments";
import {getAllUsers } from "../core/ServerApiLayer/VOne/institutions/get_users";


export const MainPageRoutes = [
    {
        path: "/institutions",
        element: <InstitutionsTable
            getAllInstitutions={getAllInstitutions}
        />,
    },
    {
        path: "/institutions/:id",
        element: <ViewDepartmentsTable
            getAllDepartments={getAllDepartments}
            />
    },
    {
        path: "/users/:institutionId",
        element: <ViewUsersPage getAllUsers={getAllUsers} />,
    },
    {
        path: "/users/:institutionId/:departmentId",
        element: <ViewUsersPage getAllUsers={getAllUsers} />,
    },
    {
        path: "/user",
        element: <NotFoundPage
        />,
    },
]
